import * as React from "react";
import Hero from "../components/homepage/hero";
import Features from "../components/homepage/Features";
import Details from "../components/homepage/Details";
import CTA from "../components/homepage/CTA";
import Seo from "../components/shared/seo";

const IndexPage = () => {
  return (
    <>
      <Seo title="PDF Reader - Read, Create and Sign PDFs" special={true} />
      <Hero></Hero>
      <Features></Features>
      <Details></Details>
      <CTA></CTA>
    </>
  );
};

export default IndexPage;

export const Head = () => <title>Home Page</title>;
